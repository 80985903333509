const ALLOWED_STYLING_PROPS = [
  'position',
  'verticalSpacing',
  'horizontalSpacing',
  'size',
];

export const DEFAULT_STYLE_PROPS = {
  position: 'right',
  verticalSpacing: 20,
  horizontalSpacing: 20,
  size: 60,
};

export function toPixel(number) {
  // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  if (Number.isNaN(number) || Number.isNaN(parseFloat(number))) return;
  return `${number}px`;
}

export function getStyleProps(
  params = {},
  defaultParams = { ...DEFAULT_STYLE_PROPS }
) {
  try {
    const styleProps = Object.keys(params).reduce(
      (prev, curr) => {
        if (ALLOWED_STYLING_PROPS.indexOf(curr) === -1) {
          throw Error(
            `Prop "${curr}" is not supported. Supported props include [${ALLOWED_STYLING_PROPS.join(
              ', '
            )}]`
          );
        }

        if (prev?.[curr] && params?.[curr]) {
          // eslint-disable-next-line no-param-reassign
          prev[curr] = params[curr];
        }
        return prev;
      },
      { ...defaultParams }
    );

    return { ...styleProps };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return { ...defaultParams };
  }
}

export function fetchInitAPIConfig() {
  return {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      referrer: window?.location?.origin,
    }),
  };
}
